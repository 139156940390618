<template scoped>
  <div>
    <el-form-item
      :rules="rules.variable"
      label="Input Variable"
      prop="operator.mathematic.inputVariable"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.operator.mathematic.inputVariable"
        placeholder=""
      >
        <el-switch
          slot="append"
          v-model="logic.operator.mathematic.inputVariableValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <div
      v-for="(val, index) in logic.operator.mathematic.logic"
      :key="index"
    >
      <el-form-item
        :label="getLabel(index)"
        :prop="'operator.mathematic.logic.' + index + '.value'"
        :style="{ marginTop: '10px' }"
      >
        <el-row>
          <el-col :span="22">
            <el-input
              v-model="logic.operator.mathematic.logic[index].value"
            >
              <el-select
                v-model="logic.operator.mathematic.logic[index].operator"
                slot="prepend"
                placeholder="Operator"
                :style="{ width: '140px' }"
              >
                <el-option
                  v-for="item in mathematicOperatorLogic"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>

              <el-switch
                slot="append"
                v-model="
                  logic.operator.mathematic.logic[index].valueType
                "
                active-text="Value"
                active-value="Value"
                inactive-text="Variable"
                inactive-value="Variable"
              >
              </el-switch>
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button
              @click="remove(index)"
              :style="{ marginLeft: '10px' }"
              circle
              type="danger"
              icon="el-icon-close"
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <el-button
        v-if="index == logic.operator.mathematic.logic.length - 1"
        @click="add"
        icon="el-icon-circle-plus-outline"
        round
        :style="{ marginBottom: '10px', marginLeft: '120px' }"
        >Add</el-button
      >
    </div>

    <div v-if="logic.operator.mathematic.logic.length == 0">
      <el-form-item :label="getLabel(0)" :style="{ marginTop: '10px' }">
        <el-button
          @click="add"
          icon="el-icon-circle-plus-outline"
          round
          :style="{ marginBottom: '10px' }"
          >Add</el-button
        >
      </el-form-item>
    </div>

    <el-form-item
      :rules="rules.variable"
      label="Output Variable"
      prop="operator.mathematic.outputVariable"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.operator.mathematic.outputVariable"
        placeholder=""
      >
      </el-input>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    getLabel: function (index) {
      if (index == 0) {
        return "Logic";
      }
      return "";
    },
    remove: function (index) {
      if (index > -1) {
        this.logic.operator.mathematic.logic.splice(index, 1);
      }
    },
    add: function () {
      this.logic.operator.mathematic.logic.push({
        operator: null,
        valueType: "Value",
        value: "",
      });
    },
    validator_variable: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the variable should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      mathematicOperatorLogic: [
        {
          id: "Subtraction",
          name: "Subtraction",
        },
        {
          id: "Addition",
          name: "Addition",
        },
        {
          id: "Multiplication",
          name: "Multiplication",
        },
        {
          id: "Division",
          name: "Division",
        },
      ],
      rules: {
        variable: [{ validator: this.validator_variable, trigger: "blur" }],
      },
    };
  },
};
</script>    